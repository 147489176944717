import { translateSingular } from '/i18n/translations'
import { useClientConfig } from './ClientConfig'
import { ensureArray } from './ensureValue'
import { formatSalary } from './getSalaryRange'
import { useLanguage } from './I18n'
import { calculateSalaryByScale } from '/features/buildingBlocks/reward-calculator/calculateSalaryByScale'

export function useTechblogFiltersWithValues({ filters, values }) {
  return filterEmpty({
    tag: addValue('tag', values.tag, { filters }),
  })
}

export function useTopicFiltersWithValues({ filters, values }) {
  return filterEmpty({
    tag: addValue('tag', values.tag, { filters }),
  })
}

export function useJobFiltersWithValues({ filters, values }) {
  const language = useLanguage()
  const config = useClientConfig()
  const { data: salaryData } = config.salary

  return React.useMemo(
    () => filterEmpty({
      remote_type: addValue('remote_type', values.remote_type, { filters }),
      country: addValue('country', values.country, { filters }),
      sub_expertise: addValue('sub_expertise', values.sub_expertise, { filters }),
      job_type: addValue('job_type', values.job_type, { filters }),
      salary_scale: addSalaryRangeValue('salary_scale', {
        min: values.salary_scale_min,
        max: values.salary_scale_max
      }, { filters, locale: language, salaryData }),
      job_branch: addValueAndSubValues('job_branch', values.job_branch, values.sub_expertise, { filters }),
    })
    ,
    [values, filters, salaryData, language]
  )
}

export function useJobAlertFiltersWithValues({ filters, values }) {
  return React.useMemo(
    () => filterEmpty({
      remote_type: addValue('remote_type', values.remote_type, { filters }),
      contract_type: addValue('contract_type', values.contract_type, { filters }),
      country: addValue('country', values.country, { filters }),
      job_branch: addValue('job_branch', values.job_branch, { filters }),
    }), [values, filters]
  )
}

function addValue(filterId, value, { filters }) {
  const filter = filters.find(x => x.id === filterId)

  if (!filter) return null

  const filterValue = ensureArray(value)
  const activeOptions = filter.options.filter(option => filterValue.includes(option.id))

  return {
    ...filter,
    value,
    activeFilterTags: activeOptions?.length
      ? [{ filterId: filter.id, activeOptions, filterValue, type: filter.type }]
      : [],
  }
}

function addSalaryRangeValue(filterId, { min, max }, { filters, locale, salaryData }) {
  const filter = filters.find(x => x.id === filterId)

  if (!filter) return null

  const minLabel = determineSalaryLabel(min, locale, salaryData).min
  const maxLabel = determineSalaryLabel(max, locale, salaryData).max

  const showMin = Boolean(min) && min !== filter.range.min
  const showMax = Boolean(max) && max !== filter.range.max

  const activeFilterTags = [
    showMin && {
      filterId: 'salary_scale_min',
      activeOptions: [{ label: `Min: ${minLabel}`, id: 'salary_scale_min' }],
      filterValue: min,
      type: filter.type
    },
    showMax && {
      filterId: 'salary_scale_max',
      activeOptions: [{ label: `Max: ${maxLabel}`, id: 'salary_scale_max' }],
      filterValue: max,
      type: filter.type
    },
  ].filter(Boolean)

  return {
    ...filter,
    value: {
      min: min || filter.range.min,
      max: max || filter.range.max,
    },
    label: {
      min: showMin ? minLabel : `${determineSalaryLabel(filter.range.min, locale, salaryData).min} ${translateSingular(locale, 'or-less')}`,
      max: showMax ? maxLabel : `${determineSalaryLabel(filter.range.max, locale, salaryData).max} ${translateSingular(locale, 'or-more')}`,
    },
    activeFilterTags
  }
}

export function determineSalaryLabel(scale, locale, salaryData) {
  const form = {
    scale,
    period: 'monthly',
    hours: 36,
    holidayPay: false,
    extraMonth: false,
    ebb: false,
    basicPay: true,
  }

  const { salaryMax, salaryMin } = calculateSalaryByScale(form, salaryData)
  return {
    min: formatSalary(salaryMin, { locale, currency: 'EUR', currencyDisplay: 'symbol' }),
    max: formatSalary(salaryMax, { locale, currency: 'EUR', currencyDisplay: 'symbol' }),
  }
}

function addValueAndSubValues(filterId, value, subfilterValue, { filters }) {
  const filter = filters.find(x => x.id === filterId)

  if (!filter) return null

  const optionsWithSubOptions = filter.options
    .map(x => ({
      ...x,
      ...(x.subfilter && {
        subfilter: {
          ...x.subfilter,
          value: subfilterValue,
        }
      })
    })
    )
  const filterValue = ensureArray(value)
  const activeOptions = optionsWithSubOptions.filter(option => filterValue.includes(option.id))

  return {
    ...filter,
    value,
    options: filter.options
      .map(x => ({
        ...x,
        ...(x.subfilter && {
          subfilter: {
            ...x.subfilter,
            value: subfilterValue,
          }
        })
      })
      ),
    activeFilterTags: activeOptions.length ? [{ filterId: filter.id, activeOptions, filterValue, type: filter.type }] : [],
  }
}

function filterEmpty(o) {
  return Object.fromEntries(
    Object.entries(o).filter(([_, v]) => Boolean(v))
  )
}
